import { useRef } from 'react';
import { decodeAnimation } from '../../utils/decode';

export default function OutlinedButton({ text }) {
  const ref = useRef(null);

  const handleMouseEnter = (ref) => {
    decodeAnimation(ref.current, true);
  };

  return (
    <button className='outline-button'>
      <span ref={ref} onMouseEnter={() => handleMouseEnter(ref)}>
        {text}
      </span>
    </button>
  );
}

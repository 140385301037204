import Logo from './logo'
import HeaderNav from './nav'
import './style.css'

export default function Header() {
    return (
        <header>
            <Logo />
            <HeaderNav />
        </header>
    )
}
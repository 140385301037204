
export default function Quote() {
    return (
        <div className="logo-quote">
            <img src="/images/logo.png" alt="qf-logo" />
            <p>
                “The Qf foundation is a group of elite experts from multiple fields ranging from databases, blockchain architecture and networks.
            </p>
        </div>
    )
}
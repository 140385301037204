import React, { useState, useRef } from 'react';
import { decodeAnimation } from '../utils/decode';
import './HeaderNav.css'; // Import the CSS file

export default function HeaderNav() {
  const [isModalOpen, setModalOpen] = useState(false);
  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const ref3 = useRef(null);

  const handleMouseEnter = (ref) => {
    decodeAnimation(ref.current, true);
  };

  const handleModalOpen = () => {
    setModalOpen(true);
    document.body.style.overflow = 'hidden'; // Disable page scrolling
    document.body.style.position = 'fixed';
    document.body.style.width = '100%';
  };

  const handleModalClose = () => {
    setModalOpen(false);
    document.body.style.overflow = 'auto';
    document.body.style.position = 'static';
    document.body.style.width = 'auto';
  };

  return (
    <nav>
      <ul>
        <li>
          <a href='#foundation' ref={ref1} onMouseEnter={() => handleMouseEnter(ref1)}>
            QF Foundation
          </a>
        </li>
        <li>
          <a href='#labs' ref={ref2} onMouseEnter={() => handleMouseEnter(ref2)}>
            QF Labs
          </a>
        </li>
        <li>
          <a
            href='#about'
            ref={ref3}
            onMouseEnter={() => handleMouseEnter(ref3)}
            onClick={handleModalOpen}
          >
            About
          </a>
        </li>
      </ul>
      {window.innerWidth <= 800 && (
        <div className="mobile-menu-icon" onClick={handleModalOpen}>
          <img src="/images/about-us-japanese.svg" alt="About Us" />
        </div>
      )}
      {isModalOpen && (
        <div className='nav-overlay'>
          <div className='nav-modal'>
            <button className='nav-closeButton' onClick={handleModalClose}>
              X
            </button>
            <div className="modal-logo">
              <img
                src='/images/logo-modal.png'
                alt='logo'
                width='40'
                height='36'
              />
            </div>
            <h2 className='nav-title'>About Us</h2>
            <p className='nav-text'>
			Quantum Fusion (QF) began with a simple yet powerful question: "Can we revolutionize blockchain through parallel computing?" This inquiry sparked a journey that has grown into a comprehensive ecosystem of blockchain innovation.
            </p>
            <h2 className='nav-title'>
              Our organization consists of three interconnected entities:
            </h2>
            <h3 className='nav-subtitle'>Quantum Fusion (QF)</h3>
            <p className='nav-text'>
			Our original project, QF, was inspired by the groundbreaking HVM2 and Bend technologies. We set out to create a parallel computing blockchain, pushing the boundaries of scalability and efficiency in decentralized systems. QF continues to focus on this mission, leveraging parallel processing to redefine possibilities in blockchain technology.
            </p>
            <h3 className='nav-subtitle'>Quantum Fusion Foundation</h3>
            <p className='nav-text'>
			As interest in our work grew and our community expanded, the QF Foundation emerged as a natural evolution. The Foundation broadens our scope beyond parallel computing, serving as our central hub for researching and developing cutting-edge blockchain technologies.
            </p>
			<p className='nav-text'>Key initiatives of the QF Foundation include:</p>
            <ul className='initiatives'>
              <li>- A massively parallel decentralized computing chain.</li>
            	<li>- A high-performance L1 blockchain, aimed at becoming the world's fastest and most useful.</li>
            	<li>- A privacy layer, advancing state-of-the-art privacy technologies for blockchain systems.</li>
            </ul>
            <h3 className='nav-subtitle'>QF Labs</h3>
            <p className='nav-text'>
			QF Labs is our incubator for experimental blockchain concepts. Here, we explore innovative ideas that are still in their infancy, nurturing potential breakthroughs in decentralized systems.
While each entity has its specific focus, they all share a common goal: to push the boundaries of blockchain technology. Together, they form a comprehensive ecosystem dedicated to creating systems that can meet and exceed the demands of tomorrow's digital world.
            </p>
			<p></p>
            <p className='nav-text'>
			Our mission is to design and build not just better systems, but to reimagine the very foundations of blockchain and privacy-preserving technologies. We believe that by pushing these boundaries, we can forge a future where blockchain technology becomes faster, more efficient, more secure, and more innovative than ever before.
            </p>
            <p className='nav-text'>
			Join us on this exciting journey as we turn visionary ideas into reality and shape the future of decentralized technologies.
Quantum Fusion - Redefining the future of blockchain, today.
            </p>
            <p className='nav-text'>
              <strong>
                Quantum Fusion - Redefining the future of blockchain, today.
              </strong>
            </p>
          </div>
        </div>
      )}
    </nav>
  );
}

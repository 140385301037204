import OutlinedButton from '../conponenets/button/outlined';
import Card from '../conponenets/card/card';
import { aboutData } from './data';
import Description from './description';
import './style.css';

export default function About() {
  return (
    <div>
      <div className='card-container'>
        {aboutData.map((card) => (
          <Card
            key={card.header}
            headerText={card.header}
            content={<Description card={card} />}
            footer={<OutlinedButton text={'More soon...'} />}
          />
        ))}
      </div>
    </div>
  );
}

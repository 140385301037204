import './style.css'

export default function HeroVideo() {
    return (
        <div className='hero-container'>
            <video autoPlay muted loop id="hero-video">
                <source src="/videos/bg-header.mp4" type="video/webm" />
            </video>
            <div className='hero-images-wrapper'>

                <img src='/images/tower.png' alt='hero-tower-image' className='tower' />
                <img src='/images/japanese-writing.png' alt='hero-tower-image' className='japanese-writings' />
            </div>

        </div>
    )
}

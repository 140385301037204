import TriangleLottie from '../lottie_icons/components/triangle';
import ImpossibleTriangleLottie from '../lottie_icons/components/impossible-triangle';
import MixedTriangleLottie from '../lottie_icons/components/mixed-triangle';

export default function Description({ card }) {
  const renderLottieIcon = (icon) => {
    switch (icon) {
      case 'triangle':
        return <TriangleLottie />;
      case 'mixed-triangle':
        return <MixedTriangleLottie />;
      case 'impossible-triangle':
        return <ImpossibleTriangleLottie />;
      default:
        return <ImpossibleTriangleLottie />;
    }
  };
  return (
    <div className='description'>
      <div className='image-container'>{renderLottieIcon(card.icon)}</div>
      <p>{card.description}</p>
    </div>
  );
}

import { useRef, useEffect } from 'react';
import { decodeAnimation } from '../utils/decode';
import './style.css';

export default function Difference() {
  const ref1 = useRef(null);
  const bordersRef = useRef([]);
  const overlayTextRef = useRef(null);

  const handleMouseEnter = (ref) => {
    decodeAnimation(ref.current, true);
  };

  useEffect(() => {
    const observerOptions = {
      root: null,
      rootMargin: '0px',
      threshold: 0.1,
    };

    const observer = new IntersectionObserver((entries, observer) => {
      let shouldAnimate = false;

      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          shouldAnimate = true;
        }
      });

      if (shouldAnimate) {
        bordersRef.current.forEach((border) => {
          if (border) {
            border.classList.add('in-view');
          }
        });

        if (overlayTextRef.current) {
          overlayTextRef.current.classList.add('in-view');
        }

        observer.disconnect();
      }
    }, observerOptions);

    bordersRef.current.forEach((border) => {
      if (border) {
        observer.observe(border);
      }
    });

    return () => {
      if (observer) {
        observer.disconnect();
      }
    };
  }, []);

  return (
    <>
      <div className='App'>
        <div className='why-container'>
          <div
            className='why'
            ref={ref1}
            onMouseEnter={() => handleMouseEnter(ref1)}
          >
            why we are different
          </div>
          <div className='japanese-writing'>
            私たちが他と違う理由は何ですか。
          </div>
        </div>
      </div>
      <div className='difference-container'>
        <div className='overlay-text' ref={overlayTextRef}>
          <p>Faster</p>
          <p>Private</p>
          <p>Decentralised</p>
        </div>
        <div className='difference-video'>
          <video autoPlay muted loop>
            <source src='/videos/memeshi.mp4' />
          </video>
        </div>

        <div className='grid-overlay'>
          <div className='row'>
            <div className='border yellow' ref={(el) => (bordersRef.current[0] = el)}>&nbsp;</div>
            <div className='border yellow' ref={(el) => (bordersRef.current[1] = el)}>&nbsp;</div>
            <div className='border' ref={(el) => (bordersRef.current[2] = el)}>&nbsp;</div>
            <div className='border' ref={(el) => (bordersRef.current[3] = el)}>&nbsp;</div>
            <div className='border' ref={(el) => (bordersRef.current[4] = el)}>&nbsp;</div>
            <div className='border' ref={(el) => (bordersRef.current[5] = el)}>&nbsp;</div>
          </div>
          <div className='row'>
            <div className='border yellow border--text' ref={(el) => (bordersRef.current[6] = el)}>Privacy Layer</div>
            <div className='border' ref={(el) => (bordersRef.current[7] = el)}>&nbsp;</div>
            <div className='border' ref={(el) => (bordersRef.current[8] = el)}>&nbsp;</div>
            <div className='border' ref={(el) => (bordersRef.current[9] = el)}>&nbsp;</div>
            <div className='border' ref={(el) => (bordersRef.current[10] = el)}>&nbsp;</div>
            <div className='border' ref={(el) => (bordersRef.current[11] = el)}>&nbsp;</div>
          </div>
          <div className='row'>
            <div className='border' ref={(el) => (bordersRef.current[12] = el)}>&nbsp;</div>
            <div className='border' ref={(el) => (bordersRef.current[13] = el)}>&nbsp;</div>
            <div className='border' ref={(el) => (bordersRef.current[14] = el)}>&nbsp;</div>
            <div className='border' ref={(el) => (bordersRef.current[15] = el)}>&nbsp;</div>
            <div className='border black' ref={(el) => (bordersRef.current[16] = el)}>&nbsp;</div>
            <div className='border black' ref={(el) => (bordersRef.current[17] = el)}>&nbsp;</div>
          </div>
          <div className='row'>
            <div className='border' ref={(el) => (bordersRef.current[18] = el)}>&nbsp;</div>
            <div className='border' ref={(el) => (bordersRef.current[19] = el)}>&nbsp;</div>
            <div className='border yellow' ref={(el) => (bordersRef.current[20] = el)}>&nbsp;</div>
            <div className='border black' ref={(el) => (bordersRef.current[21] = el)}>&nbsp;</div>
			<div className='border black border--text' ref={(el) => (bordersRef.current[21] = el)}>
				<a href="https://quantumfusion.pro" ref={(el) => (bordersRef.current[21] = el)}>Massively parallel decentralized computing</a>
			</div>

            <div className='border black' ref={(el) => (bordersRef.current[23] = el)}>&nbsp;</div>
          </div>
          <div className='row'>
            <div className='border' ref={(el) => (bordersRef.current[24] = el)}>&nbsp;</div>
            <div className='border' ref={(el) => (bordersRef.current[25] = el)}>&nbsp;</div>
			<div className='border black border--text' ref={(el) => (bordersRef.current[26] = el)}>
			<a href="https://qf.foundation/projectQ_roadmap_v0.pdf" ref={(el) => (bordersRef.current[26] = el)}>World's Fastest, Useful Blockchain</a>
			</div>
            <div className='border' ref={(el) => (bordersRef.current[27] = el)}>&nbsp;</div>
            <div className='border black' ref={(el) => (bordersRef.current[28] = el)}>&nbsp;</div>
            <div className='border' ref={(el) => (bordersRef.current[29] = el)}>&nbsp;</div>
          </div>
        </div>
      </div>
    </>
  );
}

import './style.css'

export default function Card({ headerText, content, footer }) {
    return (
        <div className="card">
            <div className='card-header'>{headerText}</div>
            <div className="content">
                {content}
            </div>
            <div className="card-footer">
                {footer}
            </div>
        </div>
    )
}
import './App.css';
import Header from './header/header';
import HeroVideo from './hero/hero';
import About from './about/about';
import QuoteSection from './quote/quote-section';
import Difference from './difference/difference';
import Footer from './footer/footer';
import { useEffect, useState } from 'react';
import SplashScreen from './splash/splash';

function App() {
  const [isSplashVisible, setSplashVisible] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setSplashVisible(false);
    }, 3000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      {isSplashVisible && <SplashScreen />}

      <Header />
      <HeroVideo />
      <div className='App'>
        <About />
      </div>
      <Difference />
      <QuoteSection />
      <div className='App'>
        <Footer />
      </div>
    </>
  );
}

export default App;

export function decodeAnimation(el, timeEachLetter = 100) {
  if (el.dataset.animating === 'true') return;

  el.dataset.animating = 'true';

  const letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'.split(
    ''
  );
  const firstLetters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('');
  const higherThan = 5;
  const timeBetweenLetters = 150;

  function randomString(len, text, index) {
    let string = '';
    for (let i = 0; i < len; i++) {
      if (i < index) {
        string += text[i];
      } else if (i > higherThan) {
        string +=
          text[i] === ' '
            ? ' '
            : letters[Math.floor(Math.random() * letters.length)];
      } else {
        string += firstLetters[Math.floor(Math.random() * firstLetters.length)];
      }
    }
    return string;
  }

  function constructText(text, index) {
    setTimeout(function () {
      if (index >= text.length) {
        el.innerHTML = text;
        el.dataset.animating = 'false';
      } else {
        const merged = randomString(text.length, text, index);
        el.innerHTML = merged;
      }
    }, index * timeBetweenLetters);
  }

  const text = el.dataset.decode || el.innerText;

  for (let i = 0, len = text.length; i <= len; i++) {
    constructText(text, i);
  }
}

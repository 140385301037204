import React, { useEffect, useState } from 'react';
import './style.css';
import logo from '../lottie_icons/data/logo.json';
import Lottie from 'lottie-react';

function SplashScreen() {
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setVisible(false);
    }, 5000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <div className={`splash-screen ${!visible ? 'fade-out' : ''}`}>
      <Lottie animationData={logo} />
    </div>
  );
}

export default SplashScreen;

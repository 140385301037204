import './style.css'

export default function Footer() {
    return (
        <div className="footer-container">
            <video autoPlay loop muted className='footer-video'>
                <source src="/videos/orb.mp4" type="video/mp4" />
            </video>
            <div className="footer-sections">
                <a href="mailto:hello@quantumfusion.pro" className="footer-mail footer-border">hello@quantumfusion.pro</a>
                <a href="https://t.me/+WfBXUKE9SGcxODdh" className="footer-telegram footer-border">Join telegram</a>
                <div className="footer-newsletter footer-border">Sign up for newsletter</div>
                <div className="footer-hash footer-border">0x6019Dcb2d0b3E0d1D8B0cE8D16191b3A4f93703d</div>
                <img src='/images/logo.png' alt='logo' className='footer-logo' />
            </div>
        </div>

    )
}

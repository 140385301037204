export const aboutData = [
  {
    header: 'QF Foundation',
    image: '/images/impossible-triangle.svg',
    icon: 'impossible-triangle',
    description:
      'The QF Foundation unites specialists in databases, blockchain architecture, and networks, assembled to bring next generation technologies to life.',
  },
  {
    header: 'QF Labs',
    image: '/images/mixed-triangle.svg',
    icon: 'mixed-triangle',
    description:
      'Our innovative research and development arm, where we explore the frontiers of blockchain, privacy and parallel computing.',
  },
  {
    header: 'QF L1 Blockchain',
    image: '/images/triangle.svg',
    icon: 'triangle',
    description: 'Designing the Optimal L1 Blockchain Infrastructure',
  },
];

import Quote from "./quote";
import './style.css';
import Video from "./video";


export default function QuoteSection() {
    return (
        <>
            <div className="quote-section">

                <div className="video-quote">
                    <Quote />
                    <div className="quote-video-wrapper">
                        <Video />
                    </div>
                </div>
                <div className="quote-avatar">
                    <img src="/images/kekeshi.png" alt="pld-japanese-man" />
                    <div>
                        <p>Memechi Kekamoto</p>
                        <p>Founder QF</p>
                    </div>
                </div>

            </div>
            <div className="quote-section-mobile">
                <div className="quote-avatar-mobile">
                    <Quote />
                    <div className="avatar-mobile">
                        <img src="/images/kekeshi.png" alt="pld-japanese-man" />
                        <div>
                            <a className="easter" href="https://x.com/memechikekamoto">Memechi Kekamoto</a>
                            <p>Founder QF</p>
                        </div>
                    </div>

                </div>
                <div className="quote-video-wrapper-mobile">
                    <Video />
                </div>


            </div>
        </>
    )
}
